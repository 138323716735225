import * as React from "react";
import Layout from "../../components/Layout";
import { graphql, PageProps } from "gatsby";
import PageTitle from "../../components/PageTitle";
import Locations from "../../components/Locations";
import BackToTop from "../../components/BackToTop";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  ImageInterface,
  LocalizedContextInterface,
  PortableText,
  MultiLineHeadline,
  Seo
} from "../../types/SanityTypes";
import "./styles.scss";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import { LocalisedPageContext } from "../../types/PageTypes";
import { identifyCountry, identifyLanguage, languagesFor } from "../../utils/identifiers";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

interface LocationSelectorDataInterface {
  locationSelector: {
    banner: {
      headline: MultiLineHeadline;
      primaryColor: {
        title: string;
      };
      _rawShortIntro: PortableText;
      _rawHeroImage: ImageInterface;
    };
    seo: Seo;
    slug: {
      current: string;
    };
  } & LocalizedContextInterface;
}

type LocationSelectorProps = LocationSelectorDataInterface & LocalizedContextInterface;

type LocationSelectorPageContext = LocalisedPageContext;

export const query = graphql`
  query locationSelector($_id: String, $language: String, $market: String) {
    locationSelector: sanityLocationSelector(_id: { eq: $_id }) {
      banner {
        headline {
          primaryText
          secondaryText
          tertiaryText
        }
        primaryColor {
          title
        }
        _rawShortIntro(resolveReferences: { maxDepth: 8 })
        _rawHeroImage(resolveReferences: { maxDepth: 8 })
      }
      seo {
        metaTitle
        metaDescription
        jsonld
      }
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const LocationSelector = (props: PageProps<LocationSelectorProps, LocationSelectorPageContext>) => {
  const language = props.pageContext.language;
  const { locale } = useSiteMetadata(language);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, country] = locale.split("_");
  const backToTopgradientProps = {
    degree: 25,
    startColor: "var(--blue-bright-color)",
    endColor: "var(--green-color)"
  };

  const locationSelector = props.data.locationSelector;
  const pageTitle = locationSelector.seo.metaTitle;

  const showLanguage = languagesFor(country) ? languagesFor(country).length > 1 : false;
  const languageText = showLanguage && language ? identifyLanguage(language) : "";

  // This is to make sure banner works of internationalisation
  const lineOne =
    locationSelector.banner.headline.primaryText.length <= 5
      ? locationSelector.banner.headline.primaryText
      : locationSelector.banner.headline.primaryText + " ";
  const lineTwo =
    locationSelector.banner.headline.secondaryText === null
      ? " "
      : locationSelector.banner.headline.secondaryText + " ";
  const lineThree =
    locationSelector.banner.headline.tertiaryText === null ? " " : locationSelector.banner.headline.tertiaryText + " ";

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"locationSelector"}
    >
      <StructuredData customData={locationSelector.seo.jsonld} />
      <SEO
        title={pageTitle}
        description={locationSelector.seo.metaDescription}
        imageUrl={locationSelector.banner._rawHeroImage.asset.url}
        imageAlt={locationSelector.banner._rawHeroImage.alt}
      />
      <div className="page_location-selector">
        <Container fluid data-testid="location-selector">
          <Row className="header-type-1">
            <Col>
              <PageTitle
                title={[lineOne, lineTwo, lineThree]}
                image={locationSelector.banner._rawHeroImage}
                color={locationSelector.banner.primaryColor.title}
              />
            </Col>
          </Row>
          {language && country && (
            <h2 className="country">{`${identifyCountry(country, language)} ${languageText}`}</h2>
          )}
          <Locations subheading={locationSelector.banner._rawShortIntro} />
          <BackToTop
            degree={backToTopgradientProps.degree}
            startColor={backToTopgradientProps.endColor}
            endColor={backToTopgradientProps.startColor}
            arrowFill={false}
          />
        </Container>
      </div>
    </Layout>
  );
};

export default LocationSelector;
