import React, { FunctionComponent } from "react";
import ExternalLink from "../ExternalLink";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { PortableText } from "../../types/SanityTypes";
import "./styles.scss";

export interface LocationsInterface {
  subheading: PortableText;
}

const Locations: FunctionComponent<LocationsInterface> = ({ subheading }) => {
  return (
    <div className="locations" data-testid="locations">
      <BlockContent blocks={subheading} />
      <div className="location-list">
        <ul id="list1">
          <li>
            <ExternalLink
              link={"https://www.rexonadeodorant.com/ae/ar/"}
              name={"Arabia Arabic"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/arabia/en/home.html"}
              name={"Arabia English"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/ar/home.html"}
              name={"Argentina"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/au/home.html"}
              name={"Australia"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/br/home.html"}
              name={"Brasil"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.degreedeodorant.com/ca/en/home.html"}
              name={"Canada English"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.degreedeodorant.com/ca/fr/home.html"}
              name={"Canada French"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/cz/home.html"}
              name={"Česká Republika"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/cl/home.html"}
              name={"Chile"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/co/home.html"}
              name={"Colombia"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/dk/home.html"}
              name={"Danmark"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/de/home.html"}
              name={"Deutschland"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/eg/ar/home.html"}
              name={"Egypt Arabic"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/eg/en/home.html"}
              name={"Egypt English"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/es/home.html"}
              name={"España"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.unileverconso.fr/rexona/"}
              name={"France"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/th/home.html"}
              name={"ประเทศไทย"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/in/home.html"}
              name={"India"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/id/home.html"}
              name={"Indonesia"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/hu/home.html"}
              name={"Magyarország"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
        </ul>
        <ul id="list2">
          <li>
            <ExternalLink
              link={"https://www.rexona.com/my/home.html"}
              name={"Malaysia"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/mx/home.html"}
              name={"México"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/nl/home.html"}
              name={"Nederland"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/au/home.html"}
              name={"New Zealand"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/at/home.html"}
              name={"Österreich"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.unilever-southlatam.com/brands/our-brands/peru/rexona.html"}
              name={"Peru"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.ru/"}
              name={"Россия"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/pl/home.html"}
              name={"Polska"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/pt/home.html"}
              name={"Portugal"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/ph/home.html"}
              name={"Philippines"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.unilever.ro/"}
              name={"România"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/ch/home.html"}
              name={"Schweiz"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/sk/home.html"}
              name={"Slovensko"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.shield.co.za/home.html"}
              name={"South Africa"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/fi/home.html"}
              name={"Suomi"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/se/home.html"}
              name={"Sverige"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/tr/home.html"}
              name={"Türkiye"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.suredeodorant.co.uk/home.html"}
              name={"United Kingdom"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.degreedeodorant.com/us/en/home.html"}
              name={"United States English"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.degreedeodorant.com/us/es/home.html"}
              name={"United States Spanish"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
          <li>
            <ExternalLink
              link={"https://www.rexona.com/uy/home.html"}
              name={"Uruguay"}
              noopener={false}
              nofollow={false}
              data-analytics-event5=""
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Locations;
